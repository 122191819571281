import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { TimeoutInterceptor } from '@shared/interceptors/timeout.interceptor';
import { DatepickerParserFormatter } from '@shared/helpers/datepicker-parser-formatter';
import { DatepickerI18n } from '@shared/helpers/datepicker-i18n';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient],
      },
      defaultLanguage: 'bg'
    }),
    ToastrModule.forRoot({
      timeOut: 4500,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      autoDismiss: true,
      enableHtml: true
    }),
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,   useClass: AuthInterceptor,    multi: true },
    { provide: HTTP_INTERCEPTORS,   useClass: TimeoutInterceptor, multi: true },
    { provide: NgbDatepickerI18n,   useClass: DatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
