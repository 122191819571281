import { HttpParams } from "@angular/common/http";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { SortingPagingData } from "@shared/helpers/sorting-paging-data";

export class RequestUtil {

  public static addSortingPagingParams(params: HttpParams, sortingFilter: SortingPagingData): HttpParams {
    if (sortingFilter) {
      if (!params) {
        params = new HttpParams();
      }
  
      if (sortingFilter != null) {
        params = params
          .set("page", sortingFilter.getPageNumber())
          .set("size", sortingFilter.getPageSize());
        if (sortingFilter.isSortingValid()) {
          params = params
            .set("sortBy", sortingFilter.sortBy)
            .set("sortAsc", sortingFilter.sortAsc === null ? 'null' : (sortingFilter.sortAsc ? 'true' : 'false'));
        }
      }
  
    }
    
    return params;
  }

  public static addRequestParams(params: HttpParams, sortingFilter: SortingPagingData, rqParams: any): HttpParams {
    if (sortingFilter) {
      params = this.addSortingPagingParams(params, sortingFilter);
    }

    if (rqParams) {
      if (!params) {
        params = new HttpParams();
      }

      Object.keys(rqParams).forEach(key => {
        let value = rqParams[key];
        if (value === false) {
          params = params.set(key, value);
        }
        if (value != undefined && value != null && value != '') {
          params = params.set(key, value);
        }
      })
    }

    return params;
  }

  static dateToIsoString(date: NgbDateStruct): string {
    return date ? date.year + "-" + ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2) : null;
  }
  
}