import { faFileDownload } from "@fortawesome/free-solid-svg-icons"

export const config = {
  appVersion: "v.0.0.1",

  pageSize:   20,
  pageSizeOptions: [20, 50, 100],
  pageCount: 5,

  formatTS:   "dd.MM.yyyy HH:mm:ss",
  formatTime: "HH:mm:ss",
  formatDate: "dd.MM.yyyy",
  formatDateChart: "DD.MM.YYYY",

  timeout: 300000,
  timeoutLogout: 60_000,

  // string patterns are anchored (wrapped in ^...$) automatically
  regexEmail:     '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}',
  regexPassword:  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()?_<>,.]).{12,}',
  regexDigits:    '\\d+',
  regexPhone:     '(0|\\+359)8[789]\\d{7}',
  regexUrl:       'https?:\/\/(www\.)?[-a-zA-Z0-9]{2,20}\.[a-zA-Z]{1,6}([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)',
  regexDigitOrLetter: '^[a-zA-Z0-9]*$',
  regexPersonalIdentifier : '^[0-9]{2}(0[1-9]|4[1-9]|5[0-2]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])[0-9]{4}$',

  newsCount: 4,
  debounceTime: 300, // ms
  iconExport: faFileDownload,
  apiDocLocation: './assets/docs/provider-api.pdf',
  QnALocation: './assets/docs/Comparetool Q&A.docx'
}

export const colors = {
  chart: {
    borderColor: 'black',
    backgroundColor: 'rgba(255,0,0,0.3)'
  }
}

export const pdfConf = {
  color: {
    link: 'blue',
    offer: 'gray',
    offerName: 'gray',
    provider: 'black',
  },
  font: {
    default: 'OpenSans',
  },
  size: {
    offer: 12,
    offerName: 14,
    provider: 14,
    link: 10,
    disclaimer: 6
  }
}

export const path = {
  public: {
    root:             'public',
    offer:            'offer',
    providers:        'providers'
  },

  admin: {
    root:             'admin',
    interrupted:      'interrupted',
    journal:          'journal',
    repErrors:        'reported-errors',
    repErrorsReview:  'reported-errors/preview',
    news:             'news',
    newsManage:       'news/manage',
    notif:            'notifications',
    notifReview:      'notifications/preview',
    offersCompare:    'offers/compare',
    providers:        'providers',
    providersAdd:     'providers/add',
    roles:            'roles',
    rolesManage:      'roles/manage',
    settings:         'settings',
    templates:        'templates',
    texts:            'texts',
    textsEdit:        'texts/edit',
    validation:       'validation',
    validationManage: 'validation/manage',
    unreadNotif:      'notifications/unread',
    usageConfig:      'usage-config',
    usageConfigEdit:  'usage-config/edit',
    offerSearchesReport:  'reports/offer-searches',
    offerVersionsReport:  'reports/offer-versions',
    uploadedOffersReport: 'reports/uploaded-offers',
    compareOfferVersionsReport: 'reports/compare-offer-versions',
  },
  
  provider: {
    root:             'provider',
    
    info:             'info',
    offers:           'offers',
    offersCompare:    'offers/compare',
    register:         'register',
  },
  
  shared: {
    login:            'login',
    users:            'users',
    usersManage:      'users/manage',

    ftp:              'offers/ftp',
    fip:              'offers/fip',
    tvp:              'offers/tvp',
    fis:              'offers/fis',
    fts:              'offers/fts',
    tvs:              'offers/tvs',
    mts:              'offers/mts',
    mtis:             'offers/mtis',
    mis:              'offers/mis',
    mtip:             'offers/mtip',
    pckg:             'offers/pckg',
  }

}