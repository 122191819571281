import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class CacheService {
  private static readonly PRE = 'c_'
  public static readonly LOCALTITIES  = CacheService.PRE + 'loc';
  public static readonly SESION_TO    = CacheService.PRE + 'sto';
  public static readonly USAGE_CONF   = CacheService.PRE + 'usgc';
  public static readonly VERSION      = CacheService.PRE + 'ver';
  public static readonly INFO_TEXTS   = CacheService.PRE + 'info';
  
  public execute<Type>(request: Observable<Type>, key: string): Observable<Type> {
    let storage: Type = this.getObj(key);
    if (storage != null) {
      return of(storage);
    } else {
      return request.pipe(map(val => {
        this.setObj(key, val);
        return val;
      }));
    }
  }

  private setObj(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private getObj(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public static remove(key: string) {
    sessionStorage.removeItem(key);
  }

  public static removeAll() {
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (key.startsWith(CacheService.PRE)) {
        sessionStorage.removeItem(key);
      }
    }
  }

}
