import { Router } from "@angular/router";
import { path } from "@env/config";

export class RouterUtil {

  public static splitPaths(...paths: any[]): any[] {
    let commands: any[] = [];
    for (let path of paths) {
      if (path instanceof Object) {
        throw new Error('Arguments cannot be objects: ' + JSON.stringify(path));
      }
      commands.push(...path.split('/'));
    }
    return commands;
  }

  public static getRoot(router: Router): string {
    if (router.url.startsWith('/' + path.provider.root)) {
      return path.provider.root;
    } else if (router.url.startsWith('/' + path.admin.root)) {
      return path.admin.root;
    } else {
      throw new Error('Invalid location. IDK what to do: ' + router.url);
    }
  }

  public static navigateToMainOrLogin(router: Router, toMain: boolean): void {
    let root = RouterUtil.getRoot(router);
    if (toMain) {
      router.navigate([root]);
    } else {
      router.navigate([root, path.shared.login]);
    }
  }

  public static getPath(key) {
    if (key === 'MOBILE_TELEPHONY_MOBILE_INTERNET_PRE')   return path.shared.mtip;
    else if (key === 'FIXED_TELEPHONY_PRE')               return path.shared.ftp;
    else if (key === 'FIXED_INTERNET_PRE')                return path.shared.fip;
    else if (key === 'TELEVISION_PRE')                    return path.shared.tvp;
    else if (key === 'MOBILE_TELEPHONY_SUB')              return path.shared.mts;
    else if (key === 'MOBILE_INTERNET_SUB')               return path.shared.mis;
    else if (key === 'MOBILE_TELEPHONY_AND_INTERNET_SUB') return path.shared.mtis;
    else if (key === 'FIXED_TELEPHONY_SUB')               return path.shared.fts;
    else if (key === 'FIXED_INTERNET_SUB')                return path.shared.fis;
    else if (key === 'TELEVISION_SUB')                    return path.shared.tvs;
    else if (key === 'PACKAGES_SUB')                      return path.shared.pckg;
    else                                                  throw Error("Impossible choise!");
  }

}