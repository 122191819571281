import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";
import { formatDate } from "@angular/common";
import { config } from "@env/config";
import { RequestUtil } from "@shared/helpers/request-util";

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {
  
  parse(value: string): NgbDateStruct {
    if (value) {
      try {
        let formatted = formatDate(value, config.formatDate, 'en-US');
        const dateParts = formatted.trim().split(".");
        return {
          month: +dateParts[0],
          day:   +dateParts[1],
          year:  +dateParts[2],
        };
      } catch(error) {
        return null;
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ? formatDate(RequestUtil.dateToIsoString(date), config.formatDate, 'en-US') : '';
  }
}
