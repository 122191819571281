import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CacheService } from '@shared/services/cache.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(private router: Router) {
    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if (event.id === 1 && event.url === event.urlAfterRedirects) {
       CacheService.removeAll();
      }
    })
  }
}
