import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { path } from '@env/config';

const routes: Routes = [
  { path: '',                 redirectTo: 'public', pathMatch: 'full' },
  { path: path.public.root,   loadChildren: () => import('@public/public.module').then(m => m.PublicModule), },
  { path: path.admin.root,    loadChildren: () => import('@admin/admin.module').then(m => m.AdminModule) },
  { path: path.provider.root, loadChildren: () => import('@providers/provider.module').then(m => m.ProviderModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
