import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouterUtil } from "@shared/helpers/router-util";
import { AuthService } from "@shared/services/auth.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing: boolean;
  private refreshTokenSubject: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    // private authService: AuthService,
  ) {
    this.isRefreshing = false;
    this.refreshTokenSubject = new BehaviorSubject<any>(null);
  }

  intercept(firstRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let token = AuthService.getAccessToken();
    if (token && !firstRequest.url.endsWith('-login')) {
      firstRequest = this.addToken(firstRequest, token);
    }

    return next.handle(firstRequest).pipe(
      catchError(err => {
        if (!firstRequest.url.endsWith("-login") && err instanceof HttpErrorResponse && err.status === 401) {
          return this.handle401Error(firstRequest, next);
        }
        // All other types of errors
        return throwError(err);
      })
    );
  }

  private handle401Error(firstRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return AuthService.refreshToken(this.http).pipe(
        switchMap(() => {
          this.isRefreshing = false;
          let newToken = AuthService.getAccessToken();
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addToken(firstRequest, newToken));
        }), catchError(err => {
          // ACCESS DENIED and cannot refresh token
          this.refreshTokenSubject = new BehaviorSubject<any>(null); // Reset the semaphore
          this.isRefreshing = false;
          sessionStorage.clear();
          RouterUtil.navigateToMainOrLogin(this.router, false);
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(refreshedToken => refreshedToken != null),
        take(1),
        switchMap(refreshedToken => {
          return next.handle(this.addToken(firstRequest, refreshedToken));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { 'Authorization': 'Bearer ' + token }});
  }

}