import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { config } from "@env/config";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TimeoutInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(timeout(config.timeout));
  }

}