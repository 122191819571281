import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { RouterUtil } from '@shared/helpers/router-util';
import { EAuthRequest } from '@shared/models/eauth-request.model';

const S_TOKEN_ACCESS    = 'accessToken';
const S_TOKEN_REFRESH   = 'refreshToken';

const URL_AUTH            = environment.URL_API + '/auth';
const URL_LOGIN_PASS_PROV = URL_AUTH + '/public/provider-passwd-login';
const URL_LOGIN_PASS_ADM  = URL_AUTH + '/public/admin-passwd-login';
const URL_LOGIN_QES       = URL_AUTH + '/public/eauth';
const URL_CHECK_VALIDITY  = URL_AUTH + '/public/check-validity?token='
const URL_REFRESH         = URL_AUTH + '/public/refresh';
const URL_REQUEST_RESET   = URL_AUTH + '/public/request-passwd-reset';
const URL_RESET_PASSWD    = URL_AUTH + '/public/passwd-reset';
const URL_LOGOUT          = URL_AUTH + '/logout';
const URL_CHANGE_PASSWD   = URL_AUTH + '/change-passwd';
const URL_REGISTER_QES    = URL_AUTH + '/public/eauth?operation=provider-register';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
  }

  public passwdLogin(email: string, password: string, adminLogin: boolean): Observable<void> {
    const req = { 'username': email, 'password': password };
    let url = adminLogin ? URL_LOGIN_PASS_ADM : URL_LOGIN_PASS_PROV;
    return this.http.post<any>(url, req)
      .pipe(map(resp => {
        let token = JSON.parse(JSON.stringify(resp));
        // store user details and jwt token
        this.registerToken(token.accessToken, token.refreshToken);
      }));
  }

  public registerToken(accessToken: string, refreshToken: string) {
    AuthService.storeData(S_TOKEN_ACCESS,  accessToken);
    AuthService.storeData(S_TOKEN_REFRESH, refreshToken);
  }

  public qesLogin(adminLogin: boolean): Observable<EAuthRequest> {
    let operation = adminLogin ? 'admin-login' : 'provider-login';
    return this.http.get<EAuthRequest>(`${URL_LOGIN_QES}?operation=${operation}`);
  }

  public qesRegister(): Observable<any> {
    return this.http.get<any>(URL_REGISTER_QES);
  }

  public static refreshToken(http: HttpClient): Observable<void> {
    const data = {
      'accessToken': AuthService.getData(S_TOKEN_ACCESS),
      'refreshToken': AuthService.getData(S_TOKEN_REFRESH)
    };

    return http.post<any>(URL_REFRESH, data).pipe(map(resp => {
      let token = JSON.parse(JSON.stringify(resp));
      AuthService.storeData(S_TOKEN_ACCESS, token.accessToken);
      AuthService.storeData(S_TOKEN_REFRESH, token.refreshToken);
    }));
  }

  public logout() {
    this.http.post<any>(URL_LOGOUT, null).subscribe();
    sessionStorage.clear();
    RouterUtil.navigateToMainOrLogin(this.router, false);
  }

  public requestPasswordReset(email: string): Observable<void> {
    return this.http.post<any>(URL_REQUEST_RESET, { 'username': email });
  }

  public checkTokenValidity(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${URL_CHECK_VALIDITY}${token}`);
  }

  public resetPassword(token: string, password: string): Observable<void> {
    let body = { 'token': token, 'password': password };
    return this.http.post<any>(URL_RESET_PASSWD, body);
  }

  public changePassword(currentPass: string, newPass: string): Observable<void> {
    let body = { 'oldPassword': currentPass, 'newPassword': newPass };
    return this.http.post<any>(URL_CHANGE_PASSWD, body);
  }

  public static getDecodedAccessToken(): any {
    try {
        return jwt_decode(this.getAccessToken());
    } catch (Error) {
        return null;
    }
  }

  public static getAccessToken(): string {
    return AuthService.getData(S_TOKEN_ACCESS);
  }

  private static storeData(key: string, val: any): void {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, val);
  }

  private static getData(key: string): string {
    return sessionStorage.getItem(key);
  }

}
