class EnvContainer {
  private static readonly DATA = {
    DEV: {
      HOSTNAME: /^.*localhost/,
      URL_API:  'http://localhost:8080/crc-comparison-ws/api',
      PROD: false
    },

    TEST: {
      HOSTNAME: /^.*172.16.112.111/,
      URL_API:  'https://172.16.112.111/api',
      PROD: true
    },

    PROD: {
      HOSTNAME: /^.*comparetool.crc.bg/,
      URL_API:  'https://comparetool.crc.bg/api',
      PROD: true
    }
  }

  private static getEnv(): any {
    const hostname = window && window.location && window.location.hostname;

    if (this.DATA.PROD.HOSTNAME.test(hostname)) {
      return this.DATA.PROD;
    } else if (this.DATA.TEST.HOSTNAME.test(hostname)) {
      return this.DATA.TEST;
    } else if (this.DATA.DEV.HOSTNAME.test(hostname)) {
      return this.DATA.DEV;
    } 
    
    console.log(`Hostname ${hostname} cannot be matched to existing settings!`);
    return this.DATA.PROD;
  }
  
  public static get(key: string): string {
    let env = EnvContainer.getEnv();
    let value = env[key];
    if (value == undefined) {
      throw new Error(`Value of ${key} in ${env} environment haven't been configured!`);
    }

    return value;
  }

}

export const environment = {
  production: EnvContainer.get('PROD'),
  // BASE_HREF:  EnvContainer.get('BASE_HREF'),
  URL_API:    EnvContainer.get('URL_API')
};
